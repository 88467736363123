import React from "react"
import "./style.scss"
import CTAButton from "@components/_atoms/button_cta"

const RoboHeading = ({ data }) => {
  return (
    <section className="home-heading">
      <div className="heading-wrap">
        <div className="div-text">
          <h1>Welcome to</h1>
          <h1>Robo-Bumpers</h1>
        </div>
        <div className="feature-image">
          <img
            alt="Phone screens displaying Learn and Earn App"
            src="/images/robo_bumpers/phones_robo_bumpers.png"
          />
        </div>
      </div>
    </section>
  )
}

export default RoboHeading
