import React from "react"
import "@components/_atoms/_style/robo-bumpers.scss"

const RoboStockDetails = ({ data }) => {
  return (
    <section className="gray-bg">
      <div className="gray-bg-text">
        <p className="title">Stock Details</p>
        <p className="sub-text">
          Explore each individual stock further through its historical price
          chart, dividend yield, or PE ratio. If you want to include the stock
          as a part of your investment portfolio, all you need to do is press
          the “Buy” button.
        </p>
      </div>
      <div className="img-sizing">
        <img
          alt="Phone showing stock market prcie"
          src="/images/robo_bumpers/phone_graph.png"
        />
      </div>
    </section>
  )
}

export default RoboStockDetails
