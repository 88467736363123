import React from "react"
import "@components/_atoms/_style/robo-bumpers.scss"

const RoboIdea = ({ data }) => {
  return (
    <section className="gray-bg">
      <div className="gray-bg-text">
        <p className="title">The Idea</p>
        <p className="sub-text">
          Robo-Bumpers balances portfolio diversification with your individual
          stock picks by automatically directing a percentage of your investment
          portfolio into a select group of diversified, publicly traded assets
          (black), while still allowing you to select how you invest the
          remaining percentage of your investment portfolio (purple).
        </p>
        <p className="sub-text">
          Robo-Bumpers lets you invest in individual stocks that interest you,
          and maintains the risk-reduction of a well-diversified portfolio.
        </p>
      </div>
      <div className="img-sizing">
        <img
          alt="Phone showing Robo-bumpers"
          src="/images/robo_bumpers/phone_portfolio.png"
        />
      </div>
    </section>
  )
}

export default RoboIdea
