import React from "react"
import SEO from "@components/seo"
import * as data from "@data/robo"
import Layout from "@components/layout"
import RoboHeading from "@components/robobumpers/RoboHeading"
import RoboIdea from "@components/robobumpers/RoboIdea"
import RoboExplore from "@components/robobumpers/RoboExplore"
import RoboStockDetails from "@components/robobumpers/RoboStockDetails"
import RoboStockPicking from "@components/robobumpers/RoboStockPicking"
import RoboAccount from "@components/robobumpers/RoboAccount"

class PledgeLandingPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { roboBumpers } = data
    return (
      <Layout type="light" pageClass="marketing">
        <SEO title={roboBumpers.title} />
        <RoboHeading />
        <RoboIdea />
        <RoboExplore />
        <RoboStockDetails />
        <RoboStockPicking />
        <RoboAccount />
      </Layout>
    )
  }
}

export default PledgeLandingPage
