import React from "react"
import "@components/_atoms/_style/robo-bumpers.scss"

const RoboExplore = ({ data }) => {
  return (
    <section className="normal-bg">
      <div className="normal-bg-text">
        <p className="title">Exploring</p>
        <p className="sub-text">
          For your individual picks, select from a pre-screened list of curated
          stocks like Netflix and Disney (if you are interested in media), or
          Apple and Alphabet (if technology is your thing!)
        </p>
      </div>
      <div className="img-sizing">
        <img
          alt="Phone showing stocks you can buy"
          src="/images/robo_bumpers/phone_stocks.png"
        />
      </div>
    </section>
  )
}

export default RoboExplore
