import React from "react"
import "@components/_atoms/_style/robo-bumpers.scss"

const RoboStockPicking = ({ data }) => {
  return (
    <section className="normal-bg">
      <div className="normal-bg-text">
        <p className="title">Stock Picking</p>
        <p className="sub-text">
          When you decide which individual stock picks you want to invest in,
          we’ll allocate a certain percentage of your portfolio to that stock.
          Robo-Bumpers limits the amount that is invested in any specific stock
          to ensure diversification and avoid too much concentration in any one
          stock pick.
        </p>
      </div>
      <div className="img-sizing">
        <img
          alt="Phone showing the confirm purchase of a stock"
          src="/images/robo_bumpers/phone_buy.png"
        />
      </div>
    </section>
  )
}

export default RoboStockPicking
