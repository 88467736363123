import React from "react"
import "@components/_atoms/_style/robo-bumpers.scss"

const RoboAccount = ({ data }) => {
  return (
    <section className="gray-bg">
      <div className="gray-bg-text">
        <p className="title">Account</p>
        <p className="sub-text">
          Changes to your account, including sales and purchases of individual
          stock picks, are reflected in your Financial Services and Portfolio
          Management Account Summary with Robo-Bumpers.
        </p>
      </div>
      <div className="img-sizing">
        <img
          alt="Phone showing account transactions and balance"
          src="/images/robo_bumpers/phone_account.png"
        />
      </div>
    </section>
  )
}

export default RoboAccount
